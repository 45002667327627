import React, { useState } from "react";
import * as yup from "yup"; // GLOBAL CUSTOM COMPONENTS

import Button from "@mui/material/Button";
import { Form, Formik, FieldArray } from "formik";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SearchGameStepper } from "../stepper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const validationSchema = yup.object().shape({
  name: yup.string().required("Name ist erforderlich"),
  ean: yup.string(),
  designer: yup.string(),
  publisher: yup.string(),
  minplayers: yup.number().nullable(),
  maxplayers: yup.number().nullable(),
  minage: yup.number().nullable(),
  playtime: yup.string().nullable(),
  datePurchased: yup.date().nullable(),
  yearpublished: yup.number().nullable(),
  coop: yup.boolean(),
  recordPoints: yup.boolean(),
  rating: yup.number().nullable(),
  description: yup.string(),
  categoryList: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
    })
  ),
  location: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .nullable(),
  image: yup.string(),
  bgdbID: yup.string(),
});

const GameForm = (props) => {
  const [openSearch, setOpenSearch] = useState(false);

  const {
    initialValues,
    handleFormSubmit,
    maingameList,
    locationList,
    categoryList,
    submitText,
    submitMultipleText,
    action,
  } = props;

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        isValid,
        handleChange,
        handleBlur,
        resetForm,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <SearchGameStepper
            action={action}
            curGame={initialValues}
            open={openSearch}
            handleDialogClose={() => setOpenSearch(false)}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
          <Card sx={{mb: 2}}>
            <CardActionArea>
              <CardContent onClick={() => setOpenSearch(true)}>
                <Typography sx={{ fontSize: "0.9375rem" }}>
                  { action === "edit" ? "Aktualisierte Daten von BoardGameGekk suchen" : "Spiel für Import von BoardGameGeek suchen "}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="name"
                  label="Name*"
                  name="name"
                  variant="standard"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.name && errors.name ? errors.name : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="maingame"
                  value={values.maingame}
                  options={maingameList}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hauptspiel"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    setFieldValue("maingame", value);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.maingame && errors.maingame)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="ean"
                  label="EAN"
                  name="ean"
                  variant="standard"
                  value={values.ean}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.ean && errors.ean)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.ean && errors.ean ? errors.ean : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="designer"
                  label="Autor"
                  name="designer"
                  variant="standard"
                  value={values.designer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.designer && errors.designer)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.designer && errors.designer ? errors.designer : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="publisher"
                  label="Publisher"
                  name="publisher"
                  variant="standard"
                  value={values.publisher}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.publisher && errors.publisher)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.publisher && errors.publisher
                    ? errors.publisher
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="minPlayers"
                  label="Min. Spieler"
                  name="minPlayers"
                  variant="standard"
                  value={values.minPlayers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.minPlayers && errors.minPlayers)}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.minPlayers && errors.minPlayers
                    ? errors.minPlayers
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="maxPlayers"
                  label="Max. Spieler"
                  name="maxPlayers"
                  variant="standard"
                  value={values.maxPlayers}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.maxPlayers && errors.maxPlayers)}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.maxPlayers && errors.maxPlayers
                    ? errors.maxPlayers
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="minAge"
                  label="Min. Alter"
                  name="minage"
                  variant="standard"
                  value={values.minAge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.minAge && errors.minAge)}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.minAge && errors.minAge ? errors.minAge : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="number"
                  id="playtime"
                  label="Spieldauer"
                  name="playtime"
                  variant="standard"
                  value={values.playtime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.playtime && errors.playtime)}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.playtime && errors.playtime ? errors.playtime : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="date"
                  id="datePurchased"
                  label="Anschaffungsdatum"
                  name="datePurchased"
                  variant="standard"
                  value={values.datePurchased}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.datePurchased && errors.datePurchased)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.datePurchased && errors.datePurchased
                    ? errors.datePurchased
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="number"
                  id="yearpublished"
                  label="Veröffentlicht"
                  name="yearpublished"
                  variant="standard"
                  value={values.yearpublished}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.yearpublished && errors.yearpublished)}
                  inputProps={{ min: 0 }}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.yearpublished && errors.yearpublished
                    ? errors.yearpublished
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="categoryList"
                  name="categoryList"
                  value={values.categoryList}
                  options={categoryList}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Genre" variant="standard" />
                  )}
                  onChange={(e, value) => {
                    setFieldValue("categoryList", value);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.categoryList && errors.categoryList)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="location"
                  value={values.location}
                  options={locationList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Standort"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => {
                    setFieldValue("location", value);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.location && errors.location)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={1}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.coop && errors.coop)}
                  variant="standard"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        id="coop"
                        name="coop"
                        checked={values.coop}
                        value={values.coop}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Koop"
                    labelPlacement="top"
                  />

                  <FormHelperText error>
                    {touched.coop && errors.coop ? errors.coop : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={4} lg={2}>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.recordPoints && errors.recordPoints)}
                  variant="standard"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="recordPoints"
                        checked={values.recordPoints}
                        value={values.recordPoints}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    }
                    label="Punkte erfassen"
                    labelPlacement="top"
                  />

                  <FormHelperText error>
                    {touched.recordPoints && errors.recordPoints
                      ? errors.recordPoints
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography component="legend">Bewertung</Typography>
                <Rating
                  name="rating"
                  precision={0.5}
                  value={values.rating}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon={<CasinoIcon color={"primary"} />}
                  emptyIcon={<CasinoIcon />}
                />
                <FormHelperText error>
                  {touched.rating && errors.rating ? errors.rating : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <TextField
                  id="bgdbID"
                  name="bgdbID"
                  label="BoardGameGeek-ID"
                  type="text"
                  variant="standard"
                  value={values.bgdbID}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.bgdbID && errors.bgdbID)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.bgdbID && errors.bgdbID ? errors.bgdbID : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="description"
                  name="description"
                  label="Beschreibung"
                  type="text"
                  variant="standard"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                />
                <FormHelperText
                  error={Boolean(touched.description && errors.description)}
                >
                  {touched.description && errors.description
                    ? errors.description
                    : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="image"
                  name="image"
                  label="Bild"
                  type="text"
                  variant="standard"
                  value={values.image}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.image && errors.image)}
                  fullWidth
                />
                <FormHelperText error>
                  {touched.image && errors.image ? errors.image : ""}
                </FormHelperText>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    Boolean(!isValid) ||
                    Boolean(!dirty) ||
                    Boolean(isSubmitting)
                  }
                  sx={{
                    display: "block",
                    margin: "auto",
                    textTransform: "uppercase",
                  }}
                >
                  {submitText}
                </Button>
              </Grid>

              {submitMultipleText && (
                <Grid item sm={12} xs={12}>
                  <Button
                    variant="contained"
                    color="gainsboro"
                    onClick={() => {
                      values.addMultiple = true;
                      handleFormSubmit(values, resetForm);
                    }}
                    disabled={
                      Boolean(!isValid) ||
                      Boolean(!dirty) ||
                      Boolean(isSubmitting)
                    }
                    sx={{
                      display: "block",
                      margin: "auto",
                      textTransform: "uppercase",
                    }}
                  >
                    {submitMultipleText}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default GameForm;
