import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import GameDataService from "../../services/game.service";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { useAlertMessageStore, useHeaderStore } from "../../store/store";

const useStyles = (theme) => ({
  root: {
    "& .MuiButton-root": {
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
    },
    "& .Mui-even": {
      backgroundColor: "#f2f2f2",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "#f6f6f6",
      },
    },
  },
});

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "ean", headerName: "EAN", width: 150 },
  { field: "maingame_title", headerName: "Hauptspiel", width: 200 },
  { field: "designer", headerName: "Autor", width: 150 },
  { field: "publisher", headerName: "Publisher", width: 150 },
  {
    field: "min_players",
    headerName: "Min. Spieler",
    type: "number",
    width: 100,
  },
  {
    field: "max_players",
    headerName: "Max. Spieler",
    type: "number",
    width: 100,
  },
  { field: "min_age", headerName: "Alter", type: "number", width: 100 },
  { field: "playtime", headerName: "Dauer", type: "number", width: 100 },
  {
    field: "date_purchased",
    headerName: "Anschaffung",
    type: "date",
    width: 150,
  },
  {
    field: "yearpublished",
    headerName: "Veröffentlichung",
    type: "string",
    width: 100,
  },
  { field: "location_name", headerName: "Standort", width: 150 },
  {
    field: "coop_text",
    headerName: "Koop",
    width: 100,
    valueGetter: (value, row) => {
      if (row.coop) {
        return "Ja";
      } else {
        return "Nein";
      }
    },
  },
  { field: "description", headerName: "Hinweis", width: 200 },
  {
    field: "last_match",
    headerName: "Zuletzt gespielt",
    type: "date",
    width: 150,
  },
  { field: "bgdb_id", headerName: "BGG-ID", width: 100 },
];

const sortModel = [
  {
    field: "name",
    sort: "asc",
  },
];

class Search extends Component {
  constructor(props) {
    super(props);

    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "search.extendedSearch",
      help: "help.extendedSearch",
    });

    this.state = {
      gameList: [],
    };
  }

  componentDidMount() {
    this.retrieveGameList();
  }

  retrieveGameList() {
    GameDataService.findByName("", "", this.collection_id)
      .then((response) => {
        const data = [];
        response.data.data.forEach((val, key) => {
          if (val.date_purchased !== null) {
            val.date_purchased = new Date(val.date_purchased);
          }
          if (val.last_match !== null) {
            val.last_match = new Date(val.last_match);
          }
          data.push(val);
        });
        this.setState({
          gameList: data,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.handled) {
          this.props.setStateValue("isLoading", false);
        } else {
          useAlertMessageStore.setState({
            alertMessage: { severity: "error", message: `error.2201` },
          });
          this.props.setStateValue("isLoading", false);
        }
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root} style={{ height: "80vh", width: "100%" }}>
          <DataGrid
            initialState={{ sortModel: sortModel }}
            rows={this.state.gameList}
            columns={columns}
            checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  delimiter: ";",
                },
              },
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Search);
