import React, { useState } from "react";
import { useAlertMessageStore } from "src/store/store";
import BGDBDataService from "src/services/bgdb.service";

import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  Stepper,
  StepLabel,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { StyledTableCell2 } from "../StyledComponents";
import LoadingWrapper from "../loading/loadingWrapper";
import noImage from "src/images/no_image.png";

const steps = ["Nach Spiel suchen", "Spiel wählen", "Daten wählen"];
const rows = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "designer",
    label: "Autor",
  },
  {
    id: "publisher",
    label: "Publisher",
  },
  {
    id: "minPlayers",
    label: "Min. Spieler",
  },
  {
    id: "maxPlayers",
    label: "Max. Spieler",
  },
  {
    id: "minAge",
    label: "Min. Alter",
  },
  {
    id: "playtime",
    label: "Spieldauer",
  },
  {
    id: "yearpublished",
    label: "Veröffentlich",
  },
  {
    id: "coop",
    label: "Koop",
  },
  {
    id: "image",
    label: "Bild",
  },
  {
    id: "bgdbID",
    label: "BGG-ID",
  },
];

const SearchGameStepper = ({
  action,
  curGame,
  open,
  handleDialogClose,
  setFieldValue,
  setFieldTouched,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(curGame.name || "");
  const [BGDBGames, setBGDBGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState({});
  const [keysToChange, setKeysToChange] = useState([]);

  const handleSelectionChange = (e) => {
    const key = e.target.id;
    if (e.target.checked) {
      const newKeys = [...keysToChange, key];
      setKeysToChange(newKeys);
    } else {
      const newKeys = keysToChange.filter((element) => element != key);
      setKeysToChange(newKeys);
    }
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    setActiveStep(0);
    setSearch(curGame.name || "");
    setBGDBGames([]);
    setSelectedGame({});
    handleDialogClose();
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        setLoading(true);
        await BGDBDataService.getMultiple(search).then((response) => {
          if (response.data.games.length === 0) {
            useAlertMessageStore.setState({
              alertMessage: {
                severity: "info",
                message: "Es wurde leider kein passendes Spiel gefunden",
              },
            });
          } else {
            setBGDBGames(response.data.games);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        });
      } catch {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Zugriff auf die BGDB-API",
          },
        });
        setBGDBGames([]);
      } finally {
        setLoading(false);
      }
    } else if (activeStep === 1) {
      let newKeys = [];
      Object.keys(selectedGame).map((key) => {
        if (
          (((curGame[key] === "" || !curGame.hasOwnProperty([key])) &&
            selectedGame[key] !== curGame[key]) ||
            action === "add") &&
          rows.some((row) => row.id === key)
        ) {
          newKeys = [...newKeys, key];
        }
      });
      setKeysToChange(newKeys);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === steps.length - 1) {
      try {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        keysToChange.map(async (key) => {
          await setFieldValue(key, selectedGame[key]);
          setFieldTouched(key, true);
        });
        handleDialogClose();
      } catch {
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Übernehmen der Daten",
          },
        });
      } finally {
        setLoading(false);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="search-game-dialog-title"
      maxWidth="xl"
      fullWidth
    >
      <LoadingWrapper open={loading}>
        <DialogTitle id="search-game-dialog-title">
          Spiel für den Import von BoardGameGeek suchen {loading}
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              return (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Box sx={{ m: 2 }}>
            {activeStep === 0 && (
              <TextField
                id="search"
                name="search"
                label="Name (oder BGG-ID)"
                variant="standard"
                value={search}
                onChange={handleChangeSearch}
                fullWidth
              />
            )}
            {activeStep === 1 &&
              (BGDBGames?.length > 0 ? (
                BGDBGames.map((game) => (
                  <Card
                    variant="outlined"
                    sx={[
                      { display: "flex", mb: 1 },
                      selectedGame.bgdbID === game.bgdbID && {
                        backgroundColor: "#ccc",
                        border: "1px solid",
                        borderColor: "primary.main",
                      },
                    ]}
                    key={game.bgdbID}
                  >
                    <CardActionArea onClick={() => setSelectedGame(game)}>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          component="img"
                          sx={{ height: "10vh" }}
                          src={game.image}
                          alt={game.name}
                          onError={(e) => (e.target.src = noImage)}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: "1 1 auto",
                            textAlign: "center",
                          }}
                        >
                          <Typography gutterBottom variant="subtitle1">
                            {game.name}
                          </Typography>
                          {game.designer && (
                            <Typography gutterBottom variant="subtitle2">
                              {game.designer}
                            </Typography>
                          )}
                          {game.publisher && (
                            <Typography gutterBottom variant="subtitle2">
                              {game.publisher}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Card>
                ))
              ) : (
                <Card variant="outlined" sx={{ mb: 1 }} />
              ))}
            {activeStep === 2 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell2>
                        <Checkbox
                          checked={
                            Object.keys(selectedGame).length ===
                            Object.keys(keysToChange).length
                          }
                        />
                      </StyledTableCell2>
                      <StyledTableCell2 sx={{ fontWeight: "bold" }}>
                        Feld
                      </StyledTableCell2>
                      {action === "edit" && (
                        <StyledTableCell2 sx={{ fontWeight: "bold" }}>
                          Aktueller Wert
                        </StyledTableCell2>
                      )}
                      <StyledTableCell2 sx={{ fontWeight: "bold" }}>
                        Neuer Wert
                      </StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => {
                      if (selectedGame.hasOwnProperty(row.id)) {
                        if (action === "edit") {
                          return (
                            <TableRow key={row.id}>
                              <StyledTableCell2>
                                <Checkbox
                                  id={row.id}
                                  onChange={handleSelectionChange}
                                  checked={keysToChange.some(
                                    (key) => key === row.id
                                  )}
                                />
                              </StyledTableCell2>
                              <StyledTableCell2>{row.label}</StyledTableCell2>
                              <StyledTableCell2>
                                {row.id === "image" && (
                                  <Box
                                    component="img"
                                    sx={{
                                      float: "left",
                                      height: "10vh",
                                      mr: 1,
                                    }}
                                    src={curGame.image}
                                    alt={curGame.name}
                                    onError={(e) => (e.target.src = noImage)}
                                  />
                                )}
                                {curGame[row.id]}
                              </StyledTableCell2>
                              <StyledTableCell2>
                                {row.id === "image" && (
                                  <Box
                                    component="img"
                                    sx={{
                                      float: "left",
                                      height: "10vh",
                                      mr: 1,
                                    }}
                                    src={selectedGame.image}
                                    alt={selectedGame.name}
                                    onError={(e) => (e.target.src = noImage)}
                                  />
                                )}
                                {selectedGame[row.id]}
                              </StyledTableCell2>
                            </TableRow>
                          );
                        }
                      }
                      return (
                        <TableRow key={row.id}>
                          <StyledTableCell2>
                            <Checkbox
                              id={row.id}
                              onChange={handleSelectionChange}
                              checked={keysToChange.some(
                                (key) => key === row.id
                              )}
                            />
                          </StyledTableCell2>
                          <StyledTableCell2>{row.label}</StyledTableCell2>
                          <StyledTableCell2>
                            {selectedGame[row.id]}
                          </StyledTableCell2>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="error" onClick={handleCancel} sx={{ mr: 1 }}>
              Abbrechen
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Zurück
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                activeStep === 1 && Object.keys(selectedGame).length === 0
              }
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Daten übernehmen" : "Weiter"}
            </Button>
          </Box>
        </DialogContent>
      </LoadingWrapper>
    </Dialog>
  );
};

export default SearchGameStepper;
