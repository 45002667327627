import http from "../http-common";
import { handleResponse } from "../helpers/handle-response"


class ClassGameDataService {
  getAll(collection_id) {
    return http.get(`/games?c=${collection_id}`).then(handleResponse);
  }
   
  getMainGames(collection_id) {
    return http.get(`/games/maingames?c=${collection_id}`);
  }

  get(id) {
    return http.get(`/games/${id}`);
  }

  getForMatch(id) {
    return http.get((`/games/${id}?m=true`))
  }

  create(data) {
    return http.post(`/games`, data);
  }

  update(id, data, collection_id) {
    return http.put(`/games/${id}?c=${collection_id}`, data);
  }

  delete(id, collection_id, title) {
    return http.delete(`/games/${id}?c=${collection_id}&title=${title}`);
  }

  deleteAll(collection_id) {
    return http.delete(`/games?c=${collection_id}`);
  }

  findByName(name, categoryFilter, collection_id, page=1, limit=1000, archive ) {
    let string = "";
    name && ( string = `${string}name=${name}&`);
    collection_id && ( string = `${string}c=${collection_id}&`);
    categoryFilter && ( string = `${string}cat=${categoryFilter}&`);
    page && ( string = `${string}p=${page}&`);
    limit && ( string = `${string}l=${limit}&`);
    archive && ( string = `${string}a=${archive}&`);
    return http.get(`/games?${string}`)
  }

  archive(data) {
    return http.post(`/games/archive`, data)
  }

  findByTitlePublic(title, categoryFilter, collection_id, page=1, limit=1000, archive ) {
    return http.get(`/public/games?title=${title}&c=${collection_id}&cat=${categoryFilter}&p=${page}&l=${limit}&a=${archive}`)
  }

  getPublic(id) {
    return http.get(`/public/games/${id}`);
  }
}

const GameDataService = new ClassGameDataService();
export default GameDataService;
