import { Link } from "react-router-dom";
import { East } from "@mui/icons-material";
import AlarmIcon from "@mui/icons-material/Alarm";
import GroupIcon from "@mui/icons-material/Group";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import RoomIcon from "@mui/icons-material/Room";
import CasinoIcon from "@mui/icons-material/Casino";
import { Avatar, Box, Card, Grid, IconButton, Rating, Chip } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { FlexBetween, FlexBox } from "src/components/flex-box";
import { H4, Span } from "src/components/Typography";
import moment from "moment";
import { AutoTextSize } from 'auto-text-size'

// styled components
const ContentWrapper = styled(Box)(({ theme, img }) => ({
  color: "white",
  backgroundSize: "cover",
  padding: "17px 30px 56px",
  backgroundPosition: "center",
  backgroundImage: `linear-gradient(to bottom,
    ${alpha(theme.palette.grey[600], 0.8)}, ${alpha(
    theme.palette.grey[900],
    0.8
  )}), 
    url('${img || "/assets/images/banners/cycle.png"}')`,
}));
const GameCard = (props) => {
  const { name, id, rating, minAge, location, lastPlay, playtime, minPlayers, maxPlayers, coverPicture, profilePicture, currentLend } =
    props;
  return (
    <Card>
      <Link to={`/games/${id}`}>
      <ContentWrapper img={profilePicture}>
        <H4 fontWeight="600" mb={1}>
          <AutoTextSize mode="oneline" maxFontSizePx={17}>{name}</AutoTextSize>
        </H4>

        <Rating
          value={rating || 0}
          color="warn"
          size="small"
          readOnly
          precision={0.5} 
          icon={<CasinoIcon sx={{ color: 'white' }} />} 
          emptyIcon={<CasinoIcon />}
          sx={{
            mb: "0.75rem",
          }}
        />

        <Grid container>
          <Grid item xs={6}>
            <FlexBox gap={1}>
              <GroupIcon
                fontSize="small"
                sx={{
                  fontSize: 17,
                  mt: "3px",
                }}
              />
              <Span color="white">
                {!minPlayers && !maxPlayers
                  ? "n/A"
                  : minPlayers && !maxPlayers
                  ? minPlayers
                  : !minPlayers && maxPlayers
                  ? maxPlayers
                  : minPlayers + " - " + maxPlayers
                }
              </Span>
            </FlexBox>
          </Grid>

          <Grid item xs={6}>
            <FlexBox gap={1}>
              <AlarmIcon
                fontSize="small"
                sx={{
                  fontSize: 17,
                  mt: "3px",
                }}
              />
              <Span color="white">{playtime? playtime + " Min": "n/A"}</Span>
            </FlexBox>
          </Grid>

          <Grid item xs={6}>
            <FlexBox gap={1}>
              <SupervisorAccountIcon
                fontSize="small"
                sx={{
                  fontSize: 17,
                  mt: "3px",
                }}
              />
              <Span color="white">{minAge ? minAge + "+" : "n/A"}</Span>
            </FlexBox>
          </Grid>

          <Grid item xs={6}>
            <FlexBox alignItems="center" gap={1}>
              <RoomIcon
                fontSize="small"
                sx={{
                  fontSize: 17,
                }}
              />
              <Span color="white">{location? location : "n/A"}</Span>
            </FlexBox>
          </Grid>

          <Grid item xs={6}>
            <FlexBox alignItems="center" gap={1}>
              <SportsEsportsIcon
                fontSize="small"
                sx={{
                  fontSize: 17,
                }}
              />
              <Span color="white">{moment(lastPlay).isValid() ? moment(lastPlay).format('DD.MM.YYYY') : "n/A"}</Span>
            </FlexBox>
          </Grid>
          
        </Grid>
      </ContentWrapper>

      <FlexBetween pl={3} pr={1}>
        <Avatar
          src={profilePicture || "missing"}
          alt={name}
          sx={{
            width: 64,
            height: 64,
            mt: "-32px",
            border: "3px solid",
            borderColor: "grey.100",
          }}
        />
        <FlexBox>
          {currentLend &&
            <Chip label="Verliehen" />
          }
        </FlexBox>
        <IconButton
          sx={{
            my: 0.5,
          }}
        >
          <East
            sx={{
              fontSize: 19,
              transform: ({ direction }) =>
                `rotate(${direction === "rtl" ? "180deg" : "0deg"})`,
            }}
          />
        </IconButton>
      </FlexBetween>
        </Link>
    </Card>
  );
};
export default GameCard;
